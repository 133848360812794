<div class="card-body">
    <div class="table-responsive">
      <table class="table text-nowrap">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Project Id</th>
            <th scope="col">Created At</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let quote of quotesData" (click)="showData(quote.id)" style="cursor: pointer;">
            <td>{{ quote.name }}</td>
            <td>{{ quote.projectId }}</td>
            <td>{{ quote.createdAt | date: 'dd,MMM yyyy' }}</td>
            <td>
              <a (click)="$event.stopPropagation(); showData(quote.id)">View</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>