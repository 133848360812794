import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { ProjectDetailsComponent } from './modules/project-details/project-details.component';
import { LoginPageComponent } from './modules/auth/pages/login-page/login-page.component';
import { ProfileViewComponent } from './modules/profile-view/profile-view.component';
import { AdminProjectsComponent } from './modules/admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { AdminQuotesComponent } from './modules/admin/admin-quotes/admin-quotes.component';
import { UserQuotesComponent } from './modules/users/user-quotes/user-quotes.component';
import { UserProjectsComponent } from './modules/users/user-projects/user-projects.component';
import { QuotesViewComponent } from './modules/quotes/quotes-view/quotes-view.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'user',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'user-projects',
        pathMatch: 'full',
      },
      {
        path: 'user-quotes',
        component: UserQuotesComponent,
      },
      {
        path: 'user-projects',
        component: UserProjectsComponent,
      },
    ]
  },
  {
    path: 'admin',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'admin-projects',
        pathMatch: 'full',
      },
      {
        path: 'admin-projects',
        component: AdminProjectsComponent,
      },
      {
        path: 'admin-users',
        component: AdminUsersComponent,
      },
      {
        path: 'admin-quotes',
        component: AdminQuotesComponent,
      },
    ]
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
      {
        path: 'project-details/:id',
        component: ProjectDetailsComponent
      },
      {
        path: 'quotes-view/:id',
        component: QuotesViewComponent,
      },
      {
        path: 'profile',
        component: ProfileViewComponent
      },
    ]
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
