<div class="card-body">
    <div class="table-responsive">
      <table class="table text-nowrap">
        <thead>
          <tr>
            <th scope="col"><input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="..."></th>
            <th scope="col">#ID</th>
            <th scope="col">Project Names</th>
            <th scope="col">Created By</th>
            <th scope="col">Sales Head</th>
            <th scope="col">Sales</th>
            <th scope="col">Lates Quote</th>
            <th scope="col">Isactive</th>
            <th scope="col">View</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row"><input class="form-check-input" type="checkbox" id="checkboxNoLabel2" value="" aria-label="..."></th>
            <td>12459</td>
             <th scope="row">Mark</th>
            <td>21,Dec 2021</td>
            <td>+1234-12340</td>
            <td>+1234-12340</td>
            <td>+1234-12340</td>
            <td><span class="badge bg-primary">Completed</span></td>
            <td> <a routerLink="/project-details">View</a> </td>
          </tr>
          <tr>
            <th scope="row"><input class="form-check-input" type="checkbox" id="checkboxNoLabel2" value="" aria-label="..."></th>
            <td>12459</td>
            <th scope="row">Monika</th>
            <td>29,April 2024</td>
            <td>+1523-12459</td>
            <td>+1523-12459</td>
            <td>+1523-12459</td>
            
            <td><span class="badge bg-warning">Failed</span></td>
            <td> <a routerLink="/project-details">View</a> </td>
          </tr>
          <tr>
            <th scope="row"><input class="form-check-input" type="checkbox" id="checkboxNoLabel2" value="" aria-label="..."></th>
            <td>12459</td>
            <th scope="row">Madina</th>
            <td>30,Mar 2024</td>
            <td>+1982-16234</td>
            <td>+1982-16234</td>
            
            <td>+1982-16234</td>
            <td><span class="badge bg-success">Successful</span></td>
            <td> <a routerLink="/project-details">View</a> </td>
          </tr>
          <tr><th scope="row"><input class="form-check-input" type="checkbox" id="checkboxNoLabel2" value="" aria-label="..."></th>
            <td>12459</td>
            <th scope="row">Bhamako</th>
            <td>18,Mar 2024</td>
        
            <td>18,Mar 2024</td>
            <td>18,Mar 2024</td>
            <td>+1526-10729</td>
            <td><span class="badge bg-secondary">Pending</span></td>
            <td> <a routerLink="/project-details">View</a> </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>