export enum ApiEndPoints {
    signIn = 'auth/login',
    signUp = 'auth/signUp',
    profileGet = 'profile/get',
    profileFind = 'profile/find',
    profileUpdate='profile/update',
    search ='prodFlow/catlog/search',

    // Project 

    projectCreate =  "project/create",
    projectUpdate =  "project/update",
    getProjectDetails =  "project/get",
    allProjects = "project/find",


    // Qoutes 
    quoteCreate =  "quote/create",
    quoteUpdate =  "quote/update",
    getQuoteDetails =  "quote/get",
    allquotes = "quote/findAll",
    

    // Qoutes-List
    quoteListCreate =  "quoteList/create",
    quoteListUpdate =  "quoteList/update",
    getQuoteListDetails =  "quoteList/get",
    allquoteLists = "quoteList/find",

 
}