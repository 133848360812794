<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4">
      <div class="card-wrap bg-white p-3 d-flex flex-column align-items-center">
        <div class="d-flex flex-column align-items-center justify-content-center">

          <div class="profile-pic-field">
            <i class="fa fa-edit"></i>
            <input type="file" (change)="handleFileInput($event)"/>
            <img class="rounded img-fluid" *ngIf="noProfilepic" [src]="imageUrl"  alt="profile-img">
          </div>

          <i *ngIf="!noProfilepic" class="fa fa-solid fa-user"></i>
          <h4 class="text-app-primary2 pt-2 text-capitalize">{{profileData.name}}</h4>
          <h6 class="h8 text-app-primary2"> Email : {{profileData.email}}</h6>
        </div>
        <div class="d-flex align-items-center">
          <p class="text-nowrap text-app-primary2 m-0 pe-2">Account type </p>
          <select name="" class="form-select ps-2">
            <option selected value="Member">Member</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8">

      <div class="card-wrap bg-white p-3">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Details" >
            <div class="card bg-white p-3 mt-4">
              <figure>
                <blockquote class="blockquote m-0">
                  <h6 class="text-app-primary2">Contact Info : </h6>
                </blockquote>
              </figure>

              <div>
                <div class="d-flex align-items-center flex-wrap mb-2">
                  <i class="text-app-muted fa me-2 fa-user" aria-hidden="true"></i>
                  <p class="p-0 m-0" >Name : <span class="text-app-muted">{{profileData.name || profileData.firstName}}</span></p>
                </div>
                <div class="d-flex align-items-center flex-wrap mb-2">
                  <i class="text-app-muted fa me-2 fa-envelope" aria-hidden="true"></i>
                  <p class="p-0 m-0" >Email : <span class="text-app-muted">{{profileData.email}}</span></p>
                </div>
                <div class="d-flex align-items-center flex-wrap mb-2">
                  <i class="text-app-muted me-2 fa fa-phone" aria-hidden="true"></i>
                  <p class="p-0 m-0" >Contact : <span class="text-app-muted">{{profileData.contact}}</span></p>
                </div>
                <div class="d-flex align-items-center flex-wrap mb-2">
                  <i class="text-app-muted me-2 fa fa-star" aria-hidden="true"></i>
                  <p class="p-0 m-0" >Status : <span class="text-app-muted">{{profileData.status}}</span></p>
                </div>
              </div>

            </div>
          </mat-tab>
          <mat-tab label="Edit Details">
            <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
            <div class="card bg-white p-3 mt-4">

              <figure>
                <blockquote class="blockquote">
                  <h6 class="text-app-primary2">Account Settings</h6>
                </blockquote>
                <figcaption class="blockquote-footer mb-0">
                  Here you can change user account information
                </figcaption>
              </figure>
      
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <div class="input-form">
                    <p class="h7 text-app-primary2">Username</p>
                    <input type="text" class="form-control" formControlName="userName" placeholder="@username.name">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Last Name</h6>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Email Address</h6>
                    <input type="email" class="form-control" formControlName="email" placeholder="@email@gmail.com">
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Number</h6>
                    <input type="text" class="form-control" formControlName="number" placeholder="@98989889">
                  </div>
                </div>
               
                <!-- <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="input-form">
                    <textarea name="" class="border-hash2 form-control" rows="7"></textarea>
                  </div>
                </div> -->

              </div>
      
            </div>

            <div class="mt-3 bg-white p-3 d-flex flex-column align-items-center">
              <div class="d-flex justify-content-end w-100 flex-wrap">
          
                <!-- <button type="button" class="brand-btn btn bg-red10 text-red1">
                  Delete Account
                </button>
           -->
                <button type="submit" class="brand-btn btn d-flex align-items-center bg-app-primary text-white2" [disabled]="this.profileForm.invalid">
                  <div>  Save Changes </div>
                  <div class="ms-2 spinner-border spinner-border-sm text-white2" role="status" *ngIf="spinner">
                    <span class="sr-only"> </span>
                  </div> 
                </button>
              </div>
            </div>
          </form>
            <!-- <div class="card bg-white p-3 mt-4">
              <figure>
                <blockquote class="blockquote">
                  <h4 class="text-app-primary2">Social Profiles</h4>
                </blockquote>
                <figcaption class="blockquote-footer">
                  Here you can set user social profiles
                </figcaption>
              </figure>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Twitter Username
                    </h6>
                    <input type="text" class="form-control" placeholder="Twitter Username">
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Facebook Username
                    </h6>
                    <input type="email" class="form-control" placeholder="Facebook Username
                      ">
                  </div>
                </div>
    
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="input-form">
                    <h6 class="h7 text-app-primary2">Github Username
                    </h6>
                    <input type="text" class="form-control" placeholder="Github Username">
                  </div>
                </div>
              </div>
            </div> -->
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>
  </div>

 

</div>