import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectDetailsComponent } from './modules/project-details/project-details.component';
import { LoginPageComponent } from './modules/auth/pages/login-page/login-page.component';
import { RegisterComponent } from './modules/auth/pages/register/register.component';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileViewComponent } from './modules/profile-view/profile-view.component';
import { SelectdropComponent } from './shared/component/selectdrop/selectdrop.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { AdminProjectsComponent } from './modules/admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { AdminQuotesComponent } from './modules/admin/admin-quotes/admin-quotes.component';
import { UserProjectsComponent } from './modules/users/user-projects/user-projects.component';
import { UserQuotesComponent } from './modules/users/user-quotes/user-quotes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { QuotesViewComponent } from './modules/quotes/quotes-view/quotes-view.component';
import { SearchmodalComponent } from './modules/quotes/searchmodal/searchmodal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ProfileViewComponent,
    FooterComponent,
    HeaderComponent,
    ContentLayoutComponent,
    ProjectDetailsComponent,
    RegisterComponent,
    SelectdropComponent,
    AdminProjectsComponent,
    AdminUsersComponent,
    AdminQuotesComponent,
    UserProjectsComponent,
    UserQuotesComponent,
    QuotesViewComponent,
    SearchmodalComponent,
  ],
  imports: [
    MatMenuModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
