import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { SearchmodalComponent } from '../searchmodal/searchmodal.component';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-quotes-view',
  templateUrl: './quotes-view.component.html',
  styleUrls: ['./quotes-view.component.scss'],
})
export class QuotesViewComponent implements OnInit {
  searchForm: FormGroup;
  items: any[] = [];
  quoteId: string = '';
  projectId: string | null = null; 
  projectDetails: any = null; 

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,

  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.quoteId = id;
        this.getQuoteDetails(this.quoteId);
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getQuoteDetails(id: string) {
    this.apiHelper.post({ id: id }, ApiEndPoints.getQuoteDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          const { quoteDetails, quoteinfoDetails } = response.data;
          this.items = quoteinfoDetails.map((item: any) => ({
            id: item.id,
            code: item?.code ,
            quoteId: item.quoteId,
            projectId: item.projectId || 'N/A',
            productInfo: item.productInfo || {
              name: 'Unknown Product',
              description: 'No product information available',
              code: 'Unknown'
            },
            qty: item.qty || 0,
            type: item.type || 'Unknown Type',
          }));

          // Check if there is a projectId and fetch project details if present
          this.projectId = quoteDetails.projectId;
          if (this.projectId) {
            this.getProjectDetails(this.projectId);
          }
        }
      });
  }

  getProjectDetails(projectId: string) {
    this.apiHelper
      .post({ id: projectId }, ApiEndPoints.getProjectDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.projectDetails = response.data.projectDetails; // Store the project details
        }
      });
  }

  // Function to handle quantity changes using buttons
  changeQuantity(item: any, change: number) {
    const newQuantity = item.qty + change;
    item.qty = newQuantity < 0 ? 0 : newQuantity;
  }

  // Function to handle quantity changes via input field
  onQuantityChange(event: Event, item: any) {
    const inputElement = event.target as HTMLInputElement;
    const newValue = parseInt(inputElement.value, 10);
    if (newValue < 0) {
      item.qty = 0;
    } else {
      item.qty = newValue;
    }
  }

  // Open the modal dialog when the search button is clicked
  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchmodalComponent, {
      width: '80%',
      data: {
        searchTerm: this.searchForm.get('searchTerm')?.value,
        selectedItems: this.items,
        quoteId: this.quoteId, // Use the dynamically set quoteId
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedItems) {
        result.selectedItems.forEach((item: any) => {
          // Add selected items to the list
          this.items.push(item);
        });
      }
    });
  }
}
