import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Product } from 'src/app/data/productDataType';

@Component({
  selector: 'app-searchmodal',
  templateUrl: './searchmodal.component.html',
  styleUrls: ['./searchmodal.component.scss'],
})
export class SearchmodalComponent {
  searchForm: FormGroup;
  filteredProducts: any = []; // Products from API
  selectedItems: Product[] = []; // Use Product interface here
  existingItems: Product[] = []; // Use Product interface here
  newItems: Product[] = []; // Use Product interface here
  quoteId: string = '';

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SearchmodalComponent>,
    private apiHelper: ApiHelper
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });

    // Initialize the quoteId and existingItems with the data passed from the parent component
    this.quoteId = data.quoteId;
    this.existingItems = data.selectedItems;

    // Search functionality for filtering products based on input
    this.searchForm
      .get('searchTerm')
      ?.valueChanges.pipe(
        debounceTime(300), // Add a 300ms delay after each keystroke
        distinctUntilChanged(), // Only emit when the current value is different from the last
        switchMap((value) =>
          this.apiHelper.post({ value: value }, ApiEndPoints.search, false)
        )
      )
      .subscribe((response) => {
        this.filteredProducts = response.data;
      });
  }

  // Function to add the selected item
  addItem(itemCode: string) {
    const selectedProduct = this.filteredProducts.find(
      (product: any) => product.code === itemCode
    );

    if (selectedProduct) {
      const totalOccurrences = this.getTypeCount(itemCode);

      // Create the new item with productDetails from the filteredProducts
      const newItem: Product = {
        code: itemCode,
        type: (totalOccurrences + 1).toString(),
        quoteId : this.quoteId,
      };

      // Add the new item to newItems array
      this.newItems.push(newItem);
    }
  }

  // Function to remove an item
  removeItem(itemToRemove: Product) {
    this.newItems = this.newItems.filter(
      (item) =>
        item.code!== itemToRemove.code || item.type !== itemToRemove.type
    );
  }

  // Close dialog and pass selected items
  closeDialog() {
    const data = {
      quoteId: this.quoteId,
      productDetails: this.newItems,
    };

    this.apiHelper
      .post(data, ApiEndPoints.quoteListCreate, true)
      .subscribe((response) => {
          const selectedItem = response.data.map((item: any) => ({
            ...item,
          }));
          this.dialogRef.close({ selectedItems: selectedItem});
      });
  }

  // Count the occurrences of the selected item in both existing and new items
  getTypeCount(itemCode: string): number {
    const occurrencesInExisting = this.existingItems.filter(
      (i) => i.code === itemCode
    ).length;

    const occurrencesInNewItems = this.newItems.filter(
      (i) => i.code === itemCode
    ).length;

    return occurrencesInExisting + occurrencesInNewItems;
 
  }
}

