<ng-container *ngIf="isObject(items)">
  <div class="d-flex justify-content-end mb-2">
    <button
      class="btn bg-app-primary2 text-white"
      data-bs-toggle="modal"
      data-bs-target="#createQuoteModal"
    >
      Create Quote
    </button>
  </div>

  <!-- create quote modal  -->
  <div
    class="modal fade"
    id="createQuoteModal"
    tabindex="-1"
    aria-labelledby="createQuoteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createQuoteModalLabel">
            Create New Quote
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="createQuoteForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
              <label for="quoteName" class="form-label">Quote Name</label>
              <input
                type="text"
                id="quoteName"
                class="form-control"
                formControlName="name"
                placeholder="Enter quote name"
              />
            </div>
            <div class="mb-3">
              <label for="quoteCreatedAt" class="form-label">Created At</label>
              <input
                type="date"
                id="quoteCreatedAt"
                class="form-control"
                formControlName="createdAt"
              />
            </div>
            <!-- projectId is taken from the URL automatically -->
            <div class="mb-3">
              <label for="projectId" class="form-label">Project ID</label>
              <input
                type="text"
                id="projectId"
                class="form-control"
                formControlName="projectId"
              />
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!createQuoteForm.valid"
              >
                Create Quote
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col col-md-3"></div>
    <div class="col-md-6">
      <div class="card mb-2">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">
             <strong> {{ items.projectDetails.name }}</strong>
            </p>
            <p class="card-text">
              <strong>Status:</strong>
              <span
              [ngClass]="{
                'badge badge-pill badge-success': items.projectDetails.isActive === 'completed',
                'badge badge-pill badge-secondary': items.projectDetails.isActive === 'pending'
              }"
            
              >
                {{
                  items.projectDetails.isActive === "completed"
                    ? "Completed"
                    : "Pending"
                }}
              </span>
            </p>
          </div>

          <p class="card-text">
            {{ items.projectDetails.createdAt | date : "dd, MMM yyyy" }}
          </p>

          <p>
            <strong>Client Name:</strong>
            {{ items.projectDetails.clientInfo.name }}
          </p>
          <p>
            <strong>Phone:</strong> {{ items.projectDetails.clientInfo.phone }}
          </p>
          <p>
            <strong>Location:</strong>
            {{ items.projectDetails.clientInfo.location }}
          </p>
        </div>
      </div>
    </div>
    <div class="col col-md-3"></div>
  </div>

  <div class="row">
    <div class="col-md-3 col-sm-12" *ngFor="let quote of items.quoteDetails">
      <div class="card mb-3">
        <div class="card-body">
          <p class="card-title fw-bold">Quote Details:</p>
          <p class="card-text">{{ quote.name }}</p>
          <p class="card-text">{{ quote.createdAt | date : "dd, MMM yyyy" }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
