<!-- Button to open the modal -->
<div class="d-flex justify-content-end mb-2">
  <button
    class="btn bg-primary text-white"
    data-bs-toggle="modal"
    data-bs-target="#createProjectModal"
  >
    Create Project
  </button>
</div>

<!-- Existing Table -->
<div class="card-body">
  <div class="table-responsive">
    <table class="table text-nowrap">
      <thead>
        <tr>
          <th scope="col">Project Names</th>
          <th scope="col">Created On</th>
          <th scope="col">Client</th>
          <th scope="col">Status</th>
          <th scope="col">View</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let project of projectData" 
            (click)="showData(project.id)" 
            style="cursor:pointer">
          <td>{{ project.name }}</td>
          <td>{{ project.createdAt | date: 'dd, MMM yyyy' }}</td>
          <td>
            <div *ngIf="project.clientInfo">
              <p>{{ project.clientInfo?.name }}.
              {{ project.clientInfo?.phone }},
              {{ project.clientInfo?.location }}</p>
            </div>
          </td>
          <td>
            <span class="badge" [ngClass]="{
              'badge badge-success': project.isActive === 'active',
              'badge badge-pill badge-secondary': project.isActive === 'completed'
            }">
              {{ project.isActive === 'active' ? 'Active' : 'Completed' }}
            </span>
          </td>
          <td>
            <a (click)="$event.stopPropagation(); showData(project.id)">View</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Create Project Modal -->
<div class="modal fade" id="createProjectModal" tabindex="-1" aria-labelledby="createProjectModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createProjectModalLabel">Create Project</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createProjectForm">
          <div class="mb-3">
            <label for="projectName" class="form-label">Project Name</label>
            <input type="text" class="form-control" id="projectName" formControlName="name" />
          </div>
      
          <div formGroupName="clientInfo">
            <div class="mb-3">
              <label for="clientName" class="form-label">Client Name</label>
              <input type="text" class="form-control" id="clientName" formControlName="name" />
            </div>
            <div class="mb-3">
              <label for="clientPhone" class="form-label">Client Phone</label>
              <input type="text" class="form-control" id="clientPhone" formControlName="phone" />
            </div>
            <div class="mb-3">
              <label for="clientLocation" class="form-label">Client Location</label>
              <input type="text" class="form-control" id="clientLocation" formControlName="location" />
            </div>
          </div>
        </form>
      </div>
      
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="submitProject()">Create</button>
      </div>
    </div>
  </div>
</div>
