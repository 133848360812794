<ng-container *ngIf="isObject(items)">
<div class="row">
  <div class="col-md-8 col-sm-12 mb-3">
    <div class="mb-3 d-flex justify-content-between align-items-center flex-wrap">
      <h4 class="text-app-primary2">Quote Details</h4>
      <form [formGroup]="searchForm">
        <button class="btn btn-primary" (click)="openSearchDialog()">Add Items</button>
      </form>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table text-nowrap">
            <thead>
              <tr>
                <th>Code</th>
                <th>Type</th>
                <th>Description</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items">
                <td>{{ item.code }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.productInfo?.description || 'Empty' }}</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="item.qty"
                    (change)="onQuantityChange($event, item)"
                    min="0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4 col-sm-12">
    <h4 class="mb-3 text-app-primary2">Project Details</h4>
    <div class="card mb-3" *ngIf="projectDetails">
      <div class="card-body">
        <div class="project-details">
          <div class="client-details">
            <h5 class="text-app-primary">Client Details</h5>
            <p class="mb-1"><strong>Project Name:</strong> {{ projectDetails.name || 'N/A' }}</p>
            <p class="mb-1"><strong>Name:</strong> {{ projectDetails.clientInfo.name || 'N/A' }}</p>
            <p class="mb-1"><strong>Address:</strong> {{ projectDetails.clientInfo.location || 'N/A' }}</p>
            <p class="mb-1"><strong>Contact:</strong> {{ projectDetails.clientInfo.phone || 'N/A' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h4 class="mb-3 text-app-primary2">Item Components</h4>
      <div class="card">
        <div class="card-body">
          <div class="component-details">
            <p>No components available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
