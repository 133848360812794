<div class="container">
    <div class="row p-5">
      <!-- Main Content Area: Search and Results -->
      <div class="col-md-8 col-sm-12">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h5 class="mb-4 text-center">Product Search</h5>
            <form [formGroup]="searchForm" class="mb-4">
              <div class="form-group">
                <label for="searchTerm">Search Products</label>
                <input id="searchTerm" matInput formControlName="searchTerm" class="form-control" placeholder="Type to search...">
              </div>
            </form>
          </div>
        </div>
  
        <!-- Search Results Section -->
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div *ngIf="searchForm.get('searchTerm')?.value && filteredProducts.length > 0; else noResults">
              <div *ngFor="let product of filteredProducts" class="d-flex align-items-center mb-1 p-2 border rounded" style="width: 100%; max-width: 400px;">
                <span class="flex-grow-1">{{ product.code }}</span>
                <button  (click)="addItem(product.code)" class="btn btn-primary btn-sm">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <!-- No Results Template -->
        <ng-template #noResults>
          <div *ngIf="searchForm.get('searchTerm')?.value" class="alert alert-warning text-center w-100" role="alert">
            <p>No results found for your search.</p>
          </div>
        </ng-template>
      </div>
  
      <!-- Sidebar for Selected Items -->
      <div class="col-md-4 col-sm-12">
        <h5 class="mb-3 text-center">Selected Items</h5>
        <div class="overflow-auto" style="max-height: 500px;">
          <div *ngFor="let item of newItems" class="card mb-3" style="border: 1px solid #ddd;">
            <div class="card-body p-2 d-flex justify-content-between align-items-center">
              <div>
                <h6 class="mb-1">{{ item.code }}</h6>
                <p class="mb-0">Type: {{ item.type }}</p>
              </div>
              <button mat-icon-button (click)="removeItem(item)" class="btn btn-outline-danger btn-sm">
                D
              </button>
            </div>
          </div>
        </div>
  
        <!-- Save button (centered in sidebar) -->
        <div class="text-center">
          <button mat-raised-button color="primary" *ngIf="newItems.length > 0" (click)="closeDialog()" class="btn btn-success">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  
