import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
declare var bootstrap: any; // Assuming you're using Bootstrap's modal features

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {
  projectData: any[] = [];
  createProjectForm: FormGroup;
  jsonb: any;

  constructor(
    public apiHelper: ApiHelper,
    private router: Router,
    public toastr: ToastrService,
    private fb: FormBuilder
  )  {
    // Initialize the form with default values and validators
    this.createProjectForm = this.fb.group({
      name: ['', Validators.required],  // Project name field
      clientInfo: this.fb.group({
        name: ['', Validators.required],  // Client name field
        phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],  // Client phone field (10-digit validation)
        location: ['', Validators.required]  // Client location field
      })
    })
  }

  ngOnInit(): void {
    this.getProjects();
  }

  // Fetch all projects
  getProjects(): void {
    this.apiHelper.post({}, ApiEndPoints.allProjects).subscribe((response) => {
      if (response && response.data) {
        this.projectData = response.data;
      } else {
        this.toastr.error('Failed to load projects.');
      }
    });
  }

  // Navigate to project details page
  showData(id: string): void {
    this.router.navigate(['/project-details', id]);
  }

  // Submit the form to create a new project
  submitProject(): void {
    if (this.createProjectForm.valid) {
      const newProject = this.createProjectForm.value;
      console.log('New Project:', newProject);

      // Assuming you'd make an API call to create the project on the server
      this.apiHelper.post(newProject, ApiEndPoints.projectCreate).subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Project created successfully!');
          
          // Add the new project to the projectData array
          this.projectData.push({
            ...newProject,
            createdAt: new Date(),
            isActive: 'active' // Assuming new projects start as active
          });

          // Close the modal
          this.closeModal();

          // Reset the form
          this.createProjectForm.reset();
        } else {
          this.toastr.error('Failed to create project.');
        }
      });
    } else {
      this.toastr.warning('Please fill in all the required fields.');
    }
  }

  // Close the Bootstrap modal programmatically
  closeModal(): void {
    const modalElement = document.getElementById('createProjectModal');
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    }
  }
}
