import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  
  createQuoteForm!: FormGroup;
  projectId!: string;

  items!: {
    projectDetails: {
      id: string;
      name: string;
      clientInfo: {
        name: string;
        phone: string;
        location: string;
      };
      createdBy: string;
      isActive: string;
      createdAt: string;
    };
    quoteDetails: Array<{
      createdBy: string;
      projectId: string;
      createdAt: string;
      name: string;
      id: string;
    }>;
  };
  dialogRef: any;

  constructor(
    private fb: FormBuilder,
    public apiHelper: ApiHelper,
    public toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Initialize the form
    this.createQuoteForm = this.fb.group({
      name: ['', Validators.required],
      createdAt: ['', Validators.required],
      projectId: [{ value: '', disabled: true }]
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.projectId = id; 
      this.getProjectDetails(id);
      this.createQuoteForm.patchValue({
        projectId: this.projectId
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getProjectDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.getProjectDetails)
      .subscribe((response) => {
        this.items = response.data;
      });
  }

  onSubmit(): void {
    if (this.createQuoteForm.valid) {
      this.createQuoteForm.value.projectId = this.projectId
      const newQuote = this.createQuoteForm.value;

      
      this.apiHelper.post(newQuote, ApiEndPoints.quoteCreate).subscribe(
        (response) => {
          this.toastr.success('Quote created successfully!');
          this.getProjectDetails(this.projectId);
          this.createQuoteForm.reset();
          
        },
        (error) => {
          this.toastr.error('Failed to create quote.');
        }
      );
    }
  }
}
