import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-user-quotes',
  templateUrl: './user-quotes.component.html',
  styleUrls: ['./user-quotes.component.scss']
})
export class UserQuotesComponent implements OnInit {
  quotesData : any =[];
  jsonb: any;
  constructor(public apiHelper: ApiHelper, public toastr: ToastrService,private router: Router) { }

  ngOnInit(): void {
    this.getQuotes();
  }

  getQuotes(){
    this.apiHelper.post({}, ApiEndPoints.allquotes).subscribe((response) => {
      this.quotesData = response.data;
    })
  }

  showData(id: string) {
    this.router.navigate(['/quotes-view', id])
  }

}
