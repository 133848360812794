import { INavData } from '../data/data-types';





export const navItems: INavData[] = [{
  title: true,
  name: 'Admin',
  subCat: [
    // {
    //   subname: 'Dashboard',
    //   icon: 'fa fa-solid fa-user',
    //   url: '/dashboard',
    // },
    {
      subname: 'Users',
      icon: 'fa fa-building',
      url: '/admin/admin-users',
    },
    {
      subname: 'Projects',
      icon: 'fa fa-building',
      url: '/admin/admin-projects',
    },
    {
      subname: 'Quotes',
      icon: 'fa fa-building',
      url: '/admin/admin-quotes',
    },
  ],
},
{
  title: true,
  name: 'Users / Engineers',
  subCat: [
    {
      subname: 'Profile',
      icon: 'fa fa-solid fa-user',
      url: '/profile',
    },
    {
      subname: 'Quotes',
      icon: 'fa fa-star',
      url: '/user/user-quotes',
    },
    {
      subname: 'Projects',
      icon: 'fa fa-briefcase',
      url: '/user/user-projects',
    },
  ],
},

];
